import { useApi } from "hooks";
import { useParams, useSearchParams } from "react-router-dom";

export default function DownloadCampaigns() {
   const api = useApi({});
   const [searchParams] = useSearchParams();
   const { clientId } = useParams();
   const filters = ["pagination=none", searchParams];

   if (clientId) {
      filters.push(`client_id=${clientId}`);
   }

   const finalFilters = filters.join("&");

   const download = async () => {
      const res = await api.get("/campaigns/download-csv?" + finalFilters);
      if (res) {
         const url = window.URL.createObjectURL(new Blob([res.data.data]));
         const link = document.createElement("a");
         link.href = url;
         link.setAttribute("download", res.data.filename);
         document.body.appendChild(link);
         link.click();
      }
   };
   return (
      <>
         <button className="basic primary" onClick={download}>
            Télécharger
         </button>
      </>
   );
}
