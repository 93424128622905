import React from "react";
import { SearchParamInput } from "components";
import { CAMPAIGN_TYPE } from "../campaignService";

export default function Filters() {
   return (
      <div className="flex gap-5 justify-between flex-wrap">
         <div>
            <h3 className="mb-2 font-semibold">Veuillez séléctionner une période</h3>
            <div className="flex flex-wrap items-center gap-3">
               <div className="flex items-center gap-2 ">
                  Du <SearchParamInput type="date" paramKey="start_date" />
               </div>
               <div className="flex items-center gap-2">
                  au <SearchParamInput type="date" paramKey="end_date" />
               </div>
               <div className="">
                  <div className="text-sm border rounded px-2 py-1">
                     <SearchParamInput type="checkbox" value="true" label="afficher les enregistrements hors période d'historisation" paramKey="withTrashed" />
                  </div>
               </div>
            </div>
            <div className="mt-3 flex gap-3">
               <SearchParamInput type="radio" paramKey="type" value={CAMPAIGN_TYPE.SMS} label="Campagnes SMS" />
               <SearchParamInput type="radio" paramKey="type" value={CAMPAIGN_TYPE.EMAIL} label="Campagnes E-mail" />
            </div>
         </div>
         <div>
            <SearchParamInput type="input" paramKey="search" />
         </div>
      </div>
   );
}
