import { ObjectSchema, number, object, string } from "yup";
import { Client } from "./types";

export enum CLIENT_STATUS {
   ACTIVE = "active",
   BLOCKED = "blocked",
}

export const formDefaultValues = {
   name: "",
   email: "",
   logo: "",
   historization_period: undefined,
   mailjet_api_key: "",
};
export const validationSchema: ObjectSchema<Client> = object({
   id: number(),
   name: string().required("Required"),
   email: string().email("Email").required("Required"),
   logo: string().required("Required"),
   historization_period: number().required("Required"),
   mailjet_api_key: string().required("Required"),
   status: string().optional(),
});
